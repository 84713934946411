const { REACT_APP_BASE_API_URL } = process.env;

const ApiConfig = {
  baseUrl: REACT_APP_BASE_API_URL,
  urls: {
    auth: {
      login: "case-managers/sessions",
      profile: "me",
      change_password: "changePasswordCM",
    },
    party: {
      get: "casemanager/parties",
      approve: "casemanager/verify-party",
      index: "casemanager/parties",
      stats: "casemanager/users/parties-stats",
      profileParties: "casemanager/users/parties",
      partyStats: "casemanager/party-stats",
      resupdate: "casemanager/updateRespondentParties",
    },
    case: {
      caseIndex: "cases",
      case: "casemanager",
      index: "casemanager/cases",
      stats: "stats",
      caseStats: "casemanager/case-stats",
      uploads: "casemanager/uploads",
      bulkUpload: "casemanager/uploads/bulkCaseUpload",
      saveSummary: "casemanager/summary",
      uploadAgreement: "negotiationAgreement",
      caseAgreementUpload: "casemanager/uploads/case",
      uploadStats: "casemanager/stats",
      case_document_uploads: "casemanager/case-document-uploads",
      mediation: "casemanager/mediation",
      negotiation: "casemanager/negotiation",
      uploadCase: "casemanager/uploads/case",
      getCaseDocuments: "casemanager/uploads",
      pendingAction: "casemanager/activities",
      offlinePayment: "payment-capture-offline",
      comments: "/comments",
      paymentRefund: "/casemanager/payments/refund",
      orders: "/orders",
      notificationCount: "/casemanager/notification-count",
      draft: "/cases/drafts",
      selectDraft: "/selects/drafts",
      select: "/selects/case",
      notes: "/notes",
      uploadDocCase: "uploads/case",
      transcripts: "/transcripts",
      updateClaimDescription: "/updateClaimDescription",
      reOpenCase: "/reOpenCase",
      getTranscriptionData: "/getTranscriptionData",
      transcriptionControlCM: "/transcriptionControlCM",
      getLiveTranscription: "/getLiveTranscription",
      enableLiveTranscription: "/enableLiveTranscription",
      getLiveTranscriptionData: "/getLiveTranscriptionData",
      updateLiveTranscriptionData: "/updateLiveTranscriptionData",
      updateTranscriptionData: "/updateTranscriptionData",
      presidingArbitrator: "/presidingArbitrator",
      startTranscription: "/startTranscription",
      transcriptionControl: "/transcriptionControl",
      downloadComments: "/downloadComments",
      getMeetingAudioTranscript: "/getMeetingAudioTranscript",
      updateAudioTranscriptData: "/updateAudioTranscriptData",
      mergeAudioTranscriptData: "/mergeAudioTranscriptData",
      getExcelFields: "/getExcelFields",
      bulkNoticeGenerate: "/bulkNoticeGenerate",
      passiveusers: "/casemanager/users",
      passiveNeutral: "/passiveNeutral",
      removePassiveUser: "/removePassiveUser",
      getUsersCount: "/casemanager/getUsersCount",
      sendDocument: "/sendDocument",
      generateInvoice: "/generateInvoice",
      getPhoneNumbers: "/getPhoneNumbers",
      getCaseDetailsForInvoice: "/getCaseDetailsForInvoice",
      dailyCauseList: "/dailyCauseList",
      pushCaseToArbitration: "/pushCaseToArbitration",
      reOpenCaseCM: "/reOpenCaseCM",
      generateInvoiceCase: "/generateInvoiceCase",
    },
    transcribers: {
      getTranscribersList: "/getTranscribersList",
      assignTranscribers: "/assignTranscribers",
      transcriber: "/transcribers",
      transcriberDetails: "/transcriber",
      transcriberCases: "/transcriberCases",
      CMTranscriberCases: "/CMTranscriberCases",
      getTranscribersListForLive: "/getTranscribersListForLive",
      transcriberCasesStats: "/transcriberCasesStats",
      getSpeakerNames: "/getSpeakerNames",
      postProcessingTranscripts: "/postProcessingTranscripts",
      getTranscribersListForRecordings: "/getTranscribersListForRecordings",
    },
    payment: {
      payments: "/payments",
    },
    transcription: {
      getTranscriptionStatus: "/getTranscriptionStatus",
      moveTranscriptToDoc: "/moveTranscriptToDoc",
      postTranscriptionData: "/postTranscriptionData",
      updateXExamTranscript: "/updateXExamTranscript",
      moveTranscriptToRecordings: "/moveTranscriptToRecordings",
      updateXExamTranscriptDoc: "/updateXExamTranscriptDoc",
    },
    meeting: {
      meetings: "/meetingJoin",
      guestmeetingUrl: "/guestMeetingUrl",
      meetingRecordings: "/meetingRecordings",
      genericMeetingJoin: "/genericMeetingJoin",
      genericMeetingRecordings: "/genericMeetingRecordings",
      genericMeetingUrl: "/genericMeetingUrl",
      genericMeeting: "/genericMeeting",
      mettingcase: "/getMeetingDet",
      endMeeting: "/endMeeting",
      publishTranscripts: "/publishTranscripts",
      recordControl: "/recordControl",
      meetingControl: "/meetingControl",
      stopTranscription: "/stopTranscription",
      getMeetingAudioDetails: "/getMeetingAudioDetails",
      getAudioFile: "/getAudioFile",
      liveTranscriptDet: "/liveTranscriptDet",
      getCasesDetails: "/getCasesDetails",
      getCaseMeetingDetails: "/getCaseMeetingDetails",
      updateMeetingDetails: "/updateMeetingDetails",
      selectMeetingAudioDetails: "/selectMeetingAudioDetails",
    },
    bundle: {
      customBundle: "/customBundle",
      bundleDocList: "/documentList",
      addPageNumberBundle: "/addPageNumberBundle",
      generateTableofContentsBundle: "/generateTableofContentsBundle",
      addAnnotationToBundles: "/addAnnotationToBundles",
      deletePagesFromBundle: "/deletePagesFromBundle",
      addSectionOrPagesToBundle: "/addSectionOrPagesToBundle",
      addPagesToBundle: "/addPagesToBundle",
    },
    systemConfig: "system-configs",
    systemConfigAgreement: "casemanager/system-configs",
    s3: "s3-url",
    notificationCount: "/notification-count",
    user: {
      index: "casemanager/users",
      update: "casemanager/update-user",
    },
  },
};

export default ApiConfig;
